import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import styled from "styled-components";
import { colors } from "../styles/variables";
import { Tags } from "../components/Tags";
import EmblaCarouselReact from "embla-carousel-react";
import { EmblaButtons } from "../components/EmblaButtons";

const Title = styled.h3`
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-size: 24px;
  &:after {
    background: ${colors.accent};
    margin-top: 15px;
    display: block;
    width: 30px;
    height: 2px;
    content: "";
  }
`;

const Slider = styled.div`
  display: flex;
  & > div {
    flex: 0 0 auto;
    min-width: 100%;
  }
`;

const Button = styled.button`
  background-color: ${colors.gold};
  border: solid 2px ${colors.gold};
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  margin-top: 20px;
  padding: 10px 20px;
  transition: 0.45s;
  cursor: pointer;
  &:hover {
    background: white;
    color: ${colors.gold};
  }
`;

const contactMessage = productName =>
  `Hi, I am interested in a bespoke piece similar to ${productName}, I am looking for ...`;

export const BespokeProductPageTemplate = ({
  featuredImage,
  title,
  description,
  shortDescription,
  tags,
  images
}) => {
  const allImages = [featuredImage, ...images.map(img => img.src)];
  const [embla, setEmbla] = useState(null);

  return (
    <>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div
              className="column"
              style={{
                margin: "0 50px",
                position: "relative",
                height: "max-content"
              }}
            >
              <EmblaCarouselReact
                emblaRef={setEmbla}
                options={{ align: "start", loop: true }}
              >
                <Slider>
                  {allImages.map((image, index) => (
                    <PreviewCompatibleImage key={index} imageInfo={{ image }} />
                  ))}
                </Slider>
                {allImages.length > 1 && (
                  <EmblaButtons embla={embla} size="30" />
                )}
              </EmblaCarouselReact>
            </div>
            <div className="column">
              <Title className="title">{title}</Title>
              <p style={{ color: colors.textColor }}>{shortDescription}</p>
              {tags && (
                <div>
                  <Tags tags={tags} />
                </div>
              )}
              <Link to={`contact?${contactMessage(title)}`}>
                <Button>Get in contact ></Button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      {description && <section
        className="section section--gradient"
        style={{ backgroundColor: colors.offWhite }}
      >
        (
          <div className="container">
            <div className="columns">
              <div className="column" />
              <div className="column is-four-fifths">{description}</div>
            </div>
          </div>
        )
      </section>}
    </>
  );
};

BespokeProductPageTemplate.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  shortDescription: PropTypes.string,
  featuredImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  id: PropTypes.string,
  tags: PropTypes.array
};

const bespokeProductPage = ({ data }) => {
  const { frontmatter, id } = data.markdownRemark;
  return (
    <Layout>
      <BespokeProductPageTemplate
        featuredImage={frontmatter.featuredImage}
        title={frontmatter.title}
        description={frontmatter.description}
        shortDescription={frontmatter.shortDescription}
        price={frontmatter.price}
        tags={frontmatter.tags}
        id={id}
        images={frontmatter.images || []}
        variantLabel={frontmatter.variantLabel}
        variants={frontmatter.variants || []}
      />
    </Layout>
  );
};

bespokeProductPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default bespokeProductPage;

export const bespokeProductPageQuery = graphql`
  query BespokeProductPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        shortDescription
        description
        tags
        price
        variantLabel
        variants {
          variantName
          variantPrice
        }
        images {
          src {
            childImageSharp {
              fluid(maxWidth: 800, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      id
    }
  }
`;
